import type { Strapi4ResponseMany, Strapi4ResponseSingle } from "@nuxtjs/strapi/dist/runtime/types";
import type Image from "@/typings/strapi/objects/image";
import type MediaObject from "~/typings/strapi/objects/media";

export function flattenObject<T>(object: Strapi4ResponseSingle<T> | undefined | null) {
  return {
    ...object?.data?.attributes,
    id: object?.data?.id
  } as T;
}

export function flattenCollection<T>(collection: Strapi4ResponseMany<T> | undefined | null) {
  if (!collection?.data) {
    return [];
  }

  return collection.data.map<T>((item) => ({
    ...item.attributes,
    id: item.id
  })) as Array<T>;
}

export function getStrapiImageLocation(image: Strapi4ResponseSingle<Image|MediaObject> | undefined | null) {
  if (!image) {
    return '';
  }

  const imageFlattened = flattenObject(image);
  return getFlattenedStrapiImageLocation(imageFlattened);
}

export function getFlattenedStrapiImageLocation(image: Image | MediaObject | undefined | null) {
  if (!image) {
    return '';
  }

  return `${image.hash}${image.ext}`;
}
