<template>
  <header class="hero">
    <div class="hero__title">
      <template v-if="showSubtitle">
        <h2 class="heading-2 text--center subtitle" v-if="subtitle">{{ subtitle }}</h2>
        <h1 class="heading-1 text--center" v-if="title">{{ title }}</h1>
      </template>
      <template v-else>
        <h1 class="heading-2 text--center solo" v-if="title" :class="{ small }">{{ title }}</h1>
      </template>
    </div>
    <div class="hero__inner">
      <div class="sun"></div>
      <DomBuilding class="dom_building" />


      <div class="hero__fallout">
        <CloudOne class="cloud_one" />
        <CloudTwo class="cloud_two" />
        <CloudThree class="cloud_three" />
        <CloudFour class="cloud_four" />

        <Wave fill="#1EB6C2" :points="5" :amplitude="10" class="upper_wave"/>
        <div class="water" />
      </div>

      <BoatOne class="boat boat_one" />
      <WaveLine class="wave_one" :width="300" :speed="0.5" />

      <BoatTwo class="boat boat_two" />
      <WaveLine class="wave_two" :width="340" :speed="0.5" :modulation="2"/>

      <BoatThree class="boat boat_three" />
      <WaveLine class="wave_three" :width="340" :speed="0.5" :modulation="3"/>

      <BoatFour class="boat boat_four" />
      <WaveLine class="wave_four" :speed="0.5" :modulation="2"/>

      <BoatFive class="boat boat_five" />
      <WaveLine class="wave_five" :width="544" :speed="0.5" />

      <!-- Smaller version -->
      <BoatFive class="boat boat_five-small" />
      <WaveLine class="wave_five_small" :width="350" :speed="0.5" :background-height="20" :modulation="4"/>

      <BoatSix class="boat boat_six" />
      <WaveLine class="wave_six" :speed="0.5" />

      <div class="hero__fallout">
        <Wave fill="#F9F9F9" :points="3" :amplitude="15" :speed="0.17" class="under_wave" />
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
// Boats
import BoatOne from '~/assets/svg/boat_1_outline.svg?component';
import BoatTwo from '~/assets/svg/boat_2_outline.svg?component';
import BoatThree from '~/assets/svg/boat_3_outline.svg?component';
import BoatFour from '~/assets/svg/boat_4_outline.svg?component';
import BoatFive from '~/assets/svg/boat_5_outline.svg?component';
import BoatSix from '~/assets/svg/boat_6_outline.svg?component';

import CloudOne from '~/assets/svg/cloud_1.svg?component';
import CloudTwo from '~/assets/svg/cloud_2.svg?component';
import CloudThree from '~/assets/svg/cloud_3.svg?component';
import CloudFour from '~/assets/svg/cloud_4.svg?component';

import DomBuilding from '~/assets/svg/dom_silh.svg?component';

const props = defineProps({
  title: {
    type: String as PropType<string>
  },
  subtitle: {
    type: String as PropType<string>
  },
  showSubtitle: {
    type: Boolean as PropType<boolean>
  },
  small: {
    type: Boolean as PropType<boolean>
  }
});

const { transitionState } = useTransitionComposable();

onMounted(() => {
  useGsap.set('.cloud_one', { opacity: 0, x: 10 });
  useGsap.set('.cloud_three', { opacity: 0, x: 10 });

  useGsap.set('.cloud_two', { opacity: 0, x: -10 });
  useGsap.set('.cloud_four', { opacity: 0, x: -10 });

  useGsap.set('.dom_building', { yPercent: 100 });
  useGsap.set('.sun', { scale: 0 });

  if (props.showSubtitle) {
    useGsap.set('.hero .heading-2 ', { opacity: 0, y: 40 });
    useGsap.set('.hero .heading-1', { opacity: 0, y: 100 });
  } else {
    useGsap.set('.hero .heading-2 ', { opacity: 0, y: 100 });
  }
  
})

watch(() => transitionState.transitionComplete, (transitionComplete) => {
  if (transitionComplete) {
    const tl = useGsap.timeline({ paused: true });

    tl
      .to('.cloud_one', {
        duration: 0.5,
        opacity: 1,
        x: 0,
        ease: 'sine.inOut'
      }, 0)
      .to('.cloud_four', {
        duration: 0.5,
        opacity: 1,
        x: 0,
        ease: 'sine.inOut'
      }, 0.1)
      .to('.cloud_three', {
        duration: 0.5,
        opacity: 1,
        x: 0,
        ease: 'sine.inOut'
      }, 0.2)
      .to('.cloud_two', {
        duration: 0.5,
        opacity: 1,
        x: 0,
        ease: 'sine.inOut'
      }, 0.3)
      .to('.dom_building', {
        duration: 0.3,
        yPercent: 0,
        ease: 'sine.inOut'
      }, 0.1)
      .to('.sun', {
        duration: 0.6,
        scale: 1,
        ease: 'expo.out'
      }, 0)

    if (props.showSubtitle) {
      tl.to('.heading-1', {
        duration: 0.6,
        y: 0,
        opacity: 1,
        ease: 'expo.out'
      }, 0.35)

      .to('.heading-2', {
        duration: 0.6,
        opacity: 1,
        y: 0,
        ease: 'expo.out'
      }, 0.5)
    } else {

      tl.to('.heading-2', {
        duration: 0.6,
        opacity: 1,
        y: 0,
        ease: 'expo.out'
      }, 0.35)
    }
      

    tl.play();
  }
});
</script>

<style lang="scss">
.hero {
  min-height: 320px;
  background: $sky;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @include breakpoint($sm) {
    min-height: 750px;
  }

  .hero__inner {
    width: 1920px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;

    .hero__fallout {
      min-width: 1920px;
      width: 100vw;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }
  }

  .hero__title {
    position: relative;
    z-index: 1;
    @include maxWidthBody(100vw);
    text-align: center;
    padding-top: 4.5rem;

    @include breakpoint($sm) {
      padding-top: 12rem;
    }
  }

  .heading-1 {
    position: relative;
    font-size: 4.6rem;
    font-weight: 900;
    letter-spacing: -.15rem;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $background;
    margin-top: 0.25rem;

    @include breakpoint($sm) {
      margin-top: 2rem;
      letter-spacing: -.2rem;
    }

    @include breakpoint($sm, $md) {
      font-size: 8rem;
    }

    @include breakpoint($md) {
      font-size: 10.6rem;
    }
  }

  .heading-2 {
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0 0.5rem;

    &.solo {
      padding: 1.5rem 1rem 0;
      text-transform: none;
      font-weight: 900;
      font-size: 2.2rem;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $background;
    }

    &.small {
      margin-top: -1rem;
    }

    &.subtitle {
      font-size: 1rem;
      font-weight: 900;
    }

    @include breakpoint($sm) {
      padding: 0 2rem;

      &.subtitle {
        font-size: 2.4rem;
      }

      &.solo {
        font-weight: 900;
        font-size: 2.8rem;
        -webkit-text-stroke-width: 2px;
      }
    }

    @include breakpoint($md) {
      font-size: 3.2rem;

      &.solo {
        font-size: 4.8rem;
      }
    }
  }

  .upper_wave {
    position: absolute;
    bottom: 200px;
    left: 0;
    right: 0;

    @include breakpoint(null, $sm) {
      bottom: 40px;
    }
  }

  .under_wave {
    position: absolute;
    bottom: -10px;

    @include breakpoint(null, $sm) {
      bottom: -10px;
    }
  }

  .water {
    height: 210px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $sea;

    @include breakpoint(null, $sm) {
      height: 80px;
    }
  }

  .boat {
    position: absolute;
    animation-name: boatfloat;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
  }

  // ====================
  // Waves
  // ====================
  .wave_one {
    position: absolute;
    bottom: -12px;
    left: 4px;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }

  .wave_two {
    position: absolute;
    bottom: -12px;
    right: -10px;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }

  .wave_three {
    position: absolute;
    bottom: -12px;
    right: 340px;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }

  .wave_four {
    position: absolute;
    bottom: -12px;
    left: 300px;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }

  .wave_five {
    position: absolute;
    bottom: -12px;
    left: 710px;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }

  .wave_five_small {
    position: absolute;
    bottom: 26px;
    left: 795px;

    @include breakpoint($sm) {
      display: none;
    }
  }

  .wave_six {
    position: absolute;
    bottom: -140px;
    left: 780px;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }


  // ====================
  // Boats
  // ====================
  .boat_one {
    width: 276px;
    position: absolute;
    bottom: 190px;
    left: 10px;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }

  .boat_two {
    width: 303px;
    bottom: 190px;
    right: 10px;
    animation-delay: 1s;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }

  .boat_three {
    width: 300px;
    bottom: 190px;
    right: 360px;
    animation-delay: 2s;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }

  .boat_four {
    width: 340px;
    bottom: 190px;
    left: 320px;
    animation-delay: 2.4s;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }

  .boat_five {
    width: 511px;
    left: 720px;
    bottom: 188px;
    animation-delay: 4s;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }

  .boat_five-small {
    width: 340px;
    left: 800px;
    bottom: 50px;

    @include breakpoint($sm) {
      display: none;
    }
  }

  .boat_six {
    width: 360px;
    bottom: 60px;
    left: 800px;

    @include breakpoint(null, $sm) {
      display: none;
    }
  }

  // ====================
  // Sun & Sky
  // ====================

  .sun {
    position: absolute;
    height: 300px;
    width: 300px;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 0 0 $sun;
    animation: pulse-animation 3s infinite;
    border-radius: 50%;
    background: $sun;
    border: 2px solid $background;

    @include breakpoint($sm) {
      width: 500px;
      height: 500px;
      bottom: 100px;
    }
  }

  .cloud_one {
    position: absolute;
    width: 435px;
    right: 80px;
    top: 300px;
  }

  .cloud_two {
    position: absolute;
    width: 320px;
    left: 180px;
    top: 40px;
  }

  .cloud_three {
    position: absolute;
    width: 240px;
    right: 400px;
    top: 80px;
  }

  .cloud_four {
    position: absolute;
    width: 400px;
    left: 180px;
    top: 280px;
  }

  .dom_building {
    position: absolute;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    height: 150px;
    top: 84px;

    @include breakpoint($sm) {
      height: 360px;
      top: 170px;
    }
  }
}

@keyframes boatfloat {
  0% {
    transform: rotate(0deg) translateY(0);
  }

  25% {
    transform: rotate(-0.1deg) translateY(-1px);
  }

  50% {
    transform: rotate(-0.5deg) translateY(-2px);
  }

  75% {
    transform: rotate(-0.1deg) translateY(-1px);
  }

  100% {
    transform: rotate(0deg) translateY(0);
  }
}



@keyframes pulse-animation {
  0% {
    /* transform: scale(0.98); */
    box-shadow: 0 0 0 0 rgba($sun, 0.8);
  }

  70% {
    /* transform: scale(1); */
    box-shadow: 0 0 0 40px rgba($sun, 0);
  }

  100% {
    /* transform: scale(0.98); */
    box-shadow: 0 0 0 0 rgba($sun, 0);
  }
}
</style>
