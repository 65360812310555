<template>
  <nav class="nav">
    <div class="navigation--desktop">
      <nuxt-link to="/" class="link link--home">
        <Logo class="logo" />
        <span class="logo_letters">UWO</span>
      </nuxt-link>

      <div class="links--left">
        <NavLink v-for="item in items" :item="item" :key="item.id"></NavLink>
        <template v-if="isAuthenticated">
          <nuxt-link to="/account" class="link">Account</nuxt-link>
          <span @click.prevent="doLogout" class="link link--primary">Log uit</span>
        </template>
        <nuxt-link to="/login" class="link link--primary" v-else>Log in</nuxt-link>
      </div>
    </div>
    <div class="navigation--mobile">
      <div class="navigation__header">
        <nuxt-link to="/" class="link link--home" @click="closeMenu">
          <Logo class="logo small"/>
        </nuxt-link>
        <NavBurger v-model="mobileOpen" />
      </div>
      <div class="navigation__content" :class="{ open: mobileOpen }">
        <NavLink v-for="item in items" :item="item" :key="item.id" is-mobile @click="closeMenu" />
        <template v-if="isAuthenticated">
          <nuxt-link to="/account" class="link" @click="closeMenu">Account</nuxt-link>
          <span @click.prevent="doLogout" class="link link--primary" @click="closeMenu">Log uit</span>
        </template>
        <nuxt-link to="/login" class="link link--primary" v-else @click="closeMenu">Log in</nuxt-link>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import Logo from '~/assets/svg/logo_duotone.svg?component';
import type Menu from '@/typings/strapi/components/menu';
import type MenuItem from '@/typings/strapi/components/menuItem';

const { logout } = useStrapiAuth();
const { findOne } = useStrapi();
const user = useStrapiUser();

const { data } = await useAsyncData(
  'header_menu',
  () => findOne<Menu>('menus', 1, { populate: '*', nested: true }),
  {
    transform: flattenObject
  }
);

const items = computed(() => flattenCollection<MenuItem>(data.value?.items));
const isAuthenticated = computed<boolean>(() => Boolean(user.value));

async function doLogout() {
  logout();
  return await navigateTo('/login');
}

// Scroll effect to add background to navbar
const { y } = useWindowScroll();

watchEffect(() => {
  if (typeof window !== "undefined") {
    if (y.value > 20) {
      document.querySelector('.nav')?.classList.add('active');
    } else {
      document.querySelector('.nav')?.classList.remove('active');
    }
  }
});

const mobileOpen = ref<boolean>(false);

const closeMenu = () => mobileOpen.value = false;
</script>

<style lang="scss">
.nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all 265ms ease-in-out;
  display: flex;
  padding: 1.4rem 4rem;
  border-bottom: 2px solid transparent;

  @include breakpoint($sm, $md) {
    padding: 1.4rem 2.5rem;
  }

  &.active {
    padding: 1.4rem 2rem;
    background: $background;
    border-bottom: 2px solid $text;
  }

  .link {
    font-size: 1.1rem;
    transition: all 300ms ease-in-out;
    text-transform: uppercase;
    font-weight: 800;
    position: relative;
  }

  .links--left {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .link--home {
    display: flex;
    align-items: center;
  }

  .link--primary {
    color: $sun;
  }
}

.logo {
  width: 3rem;
  height: 3rem;
  position: relative;
  z-index: 0;

  &.small {
    width: 2rem;
    height: 2rem;
  }
}

.logo_letters {
  font-size: 1.6rem;
  font-weight: 900;
  margin-left: .2rem;
  position: relative;
  z-index: 0;
  transform: translateX(-3rem);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 100ms ease-in-out;

  .active & {
    transform: translateX(0);
    opacity: 1;
    transition: transform 300ms ease-in-out, opacity 200ms ease-in-out 100ms;
  }
}

.navigation--desktop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include breakpoint(null, $sm) {
    display: none;
  }
}

.navigation--mobile {
  position: relative;
  height: 12px;

  @include breakpoint($sm) {
    display: none;
  }
}

.navigation__content {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  display: none;
  background: $background;
  padding-top: 5rem;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  &.open {
    display: flex;
    gap: 1rem;
  }
}

.navigation__header {
  position: fixed;
  top: 0;
  left: 0;
  padding: .5rem 1rem;
  z-index: 8;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
