<script setup lang="ts">
const props = defineProps({
  error: Object
})

const errorMessage = computed(() => {
  if (props.error?.statusCode === 404) {
    return 'Pagina niet gevonden'
  }

  return 'Er is iets misgegaan'
});

useHead({
  title: errorMessage.value
});

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <Hero :title="error.message" :subtitle="error.statusCode"/>
  <div class="mx-auto maxw--body mt-4 text--center">
    <!-- <p class="mb-2">{{ error.statusCode }}</p>
    <p class="mb-2">{{ error.message }}</p> -->
    <button @click="handleError" class="btn mb-2">Terug naar homepagina</button>

    <!-- <p>{{ error }}</p> -->
  </div>
</template>
