
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.extend(updateLocale)
dayjs.locale('nl')



dayjs.updateLocale("nl")

export default dayjs
