<template>
  <div class="load-container">
    <div class="loading-screen">
      <Wave fill="#1EB6C2" class="wave" :speed="0.6" :points="5" :height="300"/>
      <div class="loading-background">
        <div class="loader-container">
          <span class="load-loader"></span>
          <span class="load-text">Pagina is aan het laden</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.loading-screen {
  position: relative;
  padding: 0;
  width: 100%;
  height: calc(100% + 300px);
  display: flex;
  flex-direction: column;
  // top: 100%;
  top: -300px;

  .wave {
    position: relative;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 300px;
    top: 4px;
  }

  .loading-background {
    position: relative;
    width: 100%;
    height: 100%;
    background: $sea;
    z-index: 1;
  }

  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 2rem;
  }
}

.load-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
  z-index: 9999;
}

.load-text {
  font-size: 1.2rem;
  color: $clouds;
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
  line-height: 1.4;
  width: 100%;
}

.load-loader {
  width: 48px;
  height: 48px;
  display: block;
  position: relative;

  &::before,
  &::after {
    content: '';  
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid $clouds;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }

  &::after {
    animation-delay: 1s;
  }
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
