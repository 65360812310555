import { default as _91_46_46_46slug_93sQPhIcsJTFMeta } from "/home/runner/work/suwo/suwo/website/pages/[...slug].vue?macro=true";
import { default as aanpassenPjQvbXPBDKMeta } from "/home/runner/work/suwo/suwo/website/pages/account/aanpassen.vue?macro=true";
import { default as indexL8AAi4N2M9Meta } from "/home/runner/work/suwo/suwo/website/pages/account/index.vue?macro=true";
import { default as wachtwoord_45wijzigenxyhiCTfHuRMeta } from "/home/runner/work/suwo/suwo/website/pages/account/wachtwoord-wijzigen.vue?macro=true";
import { default as _91slug_93xxkCHeUPEUMeta } from "/home/runner/work/suwo/suwo/website/pages/blog/[slug].vue?macro=true";
import { default as indexArxmOvl5YSMeta } from "/home/runner/work/suwo/suwo/website/pages/blog/index.vue?macro=true";
import { default as contactx2ximj49d3Meta } from "/home/runner/work/suwo/suwo/website/pages/contact.vue?macro=true";
import { default as faq5TuqzEAeH7Meta } from "/home/runner/work/suwo/suwo/website/pages/faq.vue?macro=true";
import { default as indexZHCdkAiWGgMeta } from "/home/runner/work/suwo/suwo/website/pages/index.vue?macro=true";
import { default as loginsFNyd8u8CfMeta } from "/home/runner/work/suwo/suwo/website/pages/login.vue?macro=true";
import { default as overuglL1jd500Meta } from "/home/runner/work/suwo/suwo/website/pages/over.vue?macro=true";
import { default as registreren8X5rnOSVmsMeta } from "/home/runner/work/suwo/suwo/website/pages/registreren.vue?macro=true";
import { default as wachtwoord_45resettenlRKAxSQZahMeta } from "/home/runner/work/suwo/suwo/website/pages/wachtwoord-resetten.vue?macro=true";
import { default as wachtwoord_45vergetenc1fCw6J2PAMeta } from "/home/runner/work/suwo/suwo/website/pages/wachtwoord-vergeten.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93sQPhIcsJTFMeta?.name ?? "slug",
    path: _91_46_46_46slug_93sQPhIcsJTFMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93sQPhIcsJTFMeta || {},
    alias: _91_46_46_46slug_93sQPhIcsJTFMeta?.alias || [],
    redirect: _91_46_46_46slug_93sQPhIcsJTFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: aanpassenPjQvbXPBDKMeta?.name ?? "account-aanpassen",
    path: aanpassenPjQvbXPBDKMeta?.path ?? "/account/aanpassen",
    meta: aanpassenPjQvbXPBDKMeta || {},
    alias: aanpassenPjQvbXPBDKMeta?.alias || [],
    redirect: aanpassenPjQvbXPBDKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/account/aanpassen.vue").then(m => m.default || m)
  },
  {
    name: indexL8AAi4N2M9Meta?.name ?? "account",
    path: indexL8AAi4N2M9Meta?.path ?? "/account",
    meta: indexL8AAi4N2M9Meta || {},
    alias: indexL8AAi4N2M9Meta?.alias || [],
    redirect: indexL8AAi4N2M9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45wijzigenxyhiCTfHuRMeta?.name ?? "account-wachtwoord-wijzigen",
    path: wachtwoord_45wijzigenxyhiCTfHuRMeta?.path ?? "/account/wachtwoord-wijzigen",
    meta: wachtwoord_45wijzigenxyhiCTfHuRMeta || {},
    alias: wachtwoord_45wijzigenxyhiCTfHuRMeta?.alias || [],
    redirect: wachtwoord_45wijzigenxyhiCTfHuRMeta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/account/wachtwoord-wijzigen.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xxkCHeUPEUMeta?.name ?? "blog-slug",
    path: _91slug_93xxkCHeUPEUMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93xxkCHeUPEUMeta || {},
    alias: _91slug_93xxkCHeUPEUMeta?.alias || [],
    redirect: _91slug_93xxkCHeUPEUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexArxmOvl5YSMeta?.name ?? "blog",
    path: indexArxmOvl5YSMeta?.path ?? "/blog",
    meta: indexArxmOvl5YSMeta || {},
    alias: indexArxmOvl5YSMeta?.alias || [],
    redirect: indexArxmOvl5YSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: contactx2ximj49d3Meta?.name ?? "contact",
    path: contactx2ximj49d3Meta?.path ?? "/contact",
    meta: contactx2ximj49d3Meta || {},
    alias: contactx2ximj49d3Meta?.alias || [],
    redirect: contactx2ximj49d3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faq5TuqzEAeH7Meta?.name ?? "faq",
    path: faq5TuqzEAeH7Meta?.path ?? "/faq",
    meta: faq5TuqzEAeH7Meta || {},
    alias: faq5TuqzEAeH7Meta?.alias || [],
    redirect: faq5TuqzEAeH7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexZHCdkAiWGgMeta?.name ?? "index",
    path: indexZHCdkAiWGgMeta?.path ?? "/",
    meta: indexZHCdkAiWGgMeta || {},
    alias: indexZHCdkAiWGgMeta?.alias || [],
    redirect: indexZHCdkAiWGgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginsFNyd8u8CfMeta?.name ?? "login",
    path: loginsFNyd8u8CfMeta?.path ?? "/login",
    meta: loginsFNyd8u8CfMeta || {},
    alias: loginsFNyd8u8CfMeta?.alias || [],
    redirect: loginsFNyd8u8CfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/login.vue").then(m => m.default || m)
  },
  {
    name: overuglL1jd500Meta?.name ?? "over",
    path: overuglL1jd500Meta?.path ?? "/over",
    meta: overuglL1jd500Meta || {},
    alias: overuglL1jd500Meta?.alias || [],
    redirect: overuglL1jd500Meta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/over.vue").then(m => m.default || m)
  },
  {
    name: registreren8X5rnOSVmsMeta?.name ?? "registreren",
    path: registreren8X5rnOSVmsMeta?.path ?? "/registreren",
    meta: registreren8X5rnOSVmsMeta || {},
    alias: registreren8X5rnOSVmsMeta?.alias || [],
    redirect: registreren8X5rnOSVmsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/registreren.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45resettenlRKAxSQZahMeta?.name ?? "wachtwoord-resetten",
    path: wachtwoord_45resettenlRKAxSQZahMeta?.path ?? "/wachtwoord-resetten",
    meta: wachtwoord_45resettenlRKAxSQZahMeta || {},
    alias: wachtwoord_45resettenlRKAxSQZahMeta?.alias || [],
    redirect: wachtwoord_45resettenlRKAxSQZahMeta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/wachtwoord-resetten.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetenc1fCw6J2PAMeta?.name ?? "wachtwoord-vergeten",
    path: wachtwoord_45vergetenc1fCw6J2PAMeta?.path ?? "/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenc1fCw6J2PAMeta || {},
    alias: wachtwoord_45vergetenc1fCw6J2PAMeta?.alias || [],
    redirect: wachtwoord_45vergetenc1fCw6J2PAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/suwo/suwo/website/pages/wachtwoord-vergeten.vue").then(m => m.default || m)
  }
]