<template>
  <div class="vue-wavify-wave" ref="wave" :id="id" v-bind="separatedAttrs.rest">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="100%" :height="`${height}px`">
      <slot></slot>
      <path :d="path" :fill="fill" :style="separatedAttrs.style"/>
    </svg>
  </div>
</template>
  
<script lang="ts" setup>
defineOptions({
  inheritAttrs: false
}) 

const props = defineProps({
  paused: {
    default: false,
    type: Boolean
  },
  points: {
    default: 3,
    type: Number
  },
  speed: {
    default: 0.15,
    type: Number
  },
  height: {
    default: 100,
    type: Number
  },
  amplitude: {
    default: 20,
    type: Number
  },
  fill: {
    default: 'blue',
    type: String
  },
  id: {
    default: null,
    type: String
  }
});

const path = ref<string>();
const lastUpdate = ref<number>(0);
const elapsed = ref<number>(0);
const step = ref<number>(0);
const wave = ref<HTMLDivElement>();

let $frameId = 0;

function containerWidth () {
  return wave.value?.offsetWidth || 0;
}

function containerHeight () {
  return wave.value?.offsetHeight || 0;
}

function calculateWavePoints () {
  const wavePoints = [] as Array<{ x: number, y: number}>;
  
  for (let i = 0; i <= Math.max(props.points, 1); i ++) {
    const scale = 100;
    const x = i / props.points * containerWidth();
    const seed = (step.value + (i + i % props.points)) * props.speed * scale;
    const height = Math.sin(seed / scale) * props.amplitude;
    const y = Math.sin(seed / scale) * height + (containerHeight() / 2);
    
    wavePoints.push({x, y});
  }

  return wavePoints;
}

function buildPath (points: Array<{ x: number, y: number }>) {
  let svg = `M ${points[0].x} ${points[0].y}`;

  const initial = {
    x: (points[1].x - points[0].x) / 2,
    y: (points[1].y - points[0].y) + points[0].y + (points[1].y - points[0].y)
  };

  const cubic = (a, b) => ` C ${a.x} ${a.y} ${a.x} ${a.y} ${b.x} ${b.y}`;
  svg += cubic(initial, points[1]);

  let point = initial;

  for (let i = 1; i < points.length - 1; i ++) {
    point = {
      x: (points[i].x - point.x) + points[i].x,
      y: (points[i].y - point.y) + points[i].y
    };
    svg += cubic(point, points[i + 1]);
  }

  svg += ` L ${containerWidth()} ${containerHeight()}`;
  svg += ` L 0 ${containerHeight()} Z`;

  return svg;
}

function redraw () {
  path.value = buildPath(calculateWavePoints());
}

function draw () {
  if (!props.paused) {
    const now = +(new Date());
    
    elapsed.value += (now - lastUpdate.value);
    lastUpdate.value = now;

    const scale = 1000;

    step.value = elapsed.value * Math.PI / scale;
    redraw();
  }
}
function update () {
  draw();
  
  if ($frameId) {
    resume();
  }
}
function resume () {
  $frameId = window.requestAnimationFrame(update);
  lastUpdate.value = +new Date();
}

onMounted(() => {
  if (!$frameId) {
    resume();
  }
});

onBeforeUnmount(() => {
  window.cancelAnimationFrame($frameId);
  $frameId = 0;
})

const attrs = useAttrs();

const separatedAttrs = computed(() => {
  const { style, ...rest } = attrs
  return { style, rest };
});
</script>


<style scoped>
.vue-wavify-wave {
  display: block;
  width: 100%;   
}

.vue-wavify-wave svg {
  width: 100%;
}
</style>
