const { toggleTransitionComplete } = useTransitionComposable();

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', () => {
    const tl = useGsap.timeline({
      paused: true,
      onComplete: () => {
        toggleTransitionComplete(true)
      }
    });

    tl
      .to(".loading-screen", {
        duration: 1.4,
        top: window.innerHeight,
        ease: "expo.inOut",
      })
      .to(".loading-screen", {
        duration: 0,
        top: '100%'
      })
      .to('.loader-container', {
        duration: 0.265,
        opacity: 0,
        ease: 'power2.inOut'
      }, 0.2)

    tl.play();
  })
});
