<template>
  <div class="hamburger_wrapper" :class="{ active: modelValue }">
    <div class="hamburger" @click="toggle" :class="{ active: modelValue }">
      <div class="hambuger__inner" :class="{ active: modelValue }"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  modelValue: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const emit = defineEmits(['update:modelValue'])

const toggle = () => emit('update:modelValue', !props.modelValue);
</script>

<style lang="scss">
$hamburger_size: 2;
$hamburger_animation_speed: 300ms;
$hamburger_animation_delay: 50ms;

.hamburger_wrapper {
  height: 36px;
  width: 36px;
  padding: 0.6rem 0.5rem;
  border-radius: 0.5rem;

  &.active {
    box-shadow: none;
  }
}

.hamburger {
  cursor: pointer;
  width: $hamburger_size * 10px;
  height: $hamburger_size * 8px;

  .hambuger__inner {
    background: black;
    width: $hamburger_size * 10px;
    height: $hamburger_size * 1px;
    position: relative;
    transition: background 10ms $hamburger_animation_speed ease;
    transform: translateY($hamburger_size * 4px);

    &::before,
    &::after {
      transition: top $hamburger_animation_speed $hamburger_animation_speed + $hamburger_animation_delay ease,
                  transform $hamburger_animation_speed $hamburger_animation_delay ease,
                  background 75ms ease-in-out;
      position: absolute;
      background: black;
      width: $hamburger_size * 10px;
      height: $hamburger_size * 1px;
      content: '';
    }

    &::before {
      top: $hamburger_size * -3px;
    }

    &::after {
      top: $hamburger_size * 3px;
    }

    &.active {
      background: transparent;

      &::after,
      &::before {
        top: 0;
        background: black;
        transition: top $hamburger_animation_speed $hamburger_animation_delay ease,
                    transform $hamburger_animation_speed $hamburger_animation_speed + $hamburger_animation_delay ease,
                    background 75ms ease-in-out;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}
</style>
