<template>
  <svg :style="`width: ${width}px; height: ${calcHeight}px`">
    <path :d="bottom_path" class="bottom_path" v-if="background" :fill="backgroundColor"></path>
    <path :d="path" :stroke-width="`${strokeWidth}px`" fill="none" :stroke="color"></path>
  </svg>
</template>

<script lang="ts" setup>
const props = defineProps({
  pause: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  width: {
    type: Number as PropType<number>,
    default: 400
  },
  spacing: {
    type: Number as PropType<number>,
    default: 20
  },
  speed: {
    type: Number as PropType<number>,
    default: 1.2
  },
  reverse: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  modulation: {
    type: Number as PropType<number>,
    default: 5
  },
  background: {
    type: Boolean as PropType<boolean>,
    default: true
  },
  backgroundHeight: {
    type: Number as PropType<number>,
    default: 200
  },
  backgroundColor: {
    type: String as PropType<string>,
    default: '#1EB6C2'
  },
  color: {
    type: String as PropType<string>,
    default: '#95E9EE'
  },
  strokeWidth: {
    type: Number as PropType<number>,
    default: 4
  }
})

const xs = ref<Array<number>>([]);
let $frameId = 0;

const path = ref<string>();
const bottom_path = ref<string>();

const waveHeight = computed(() => (props.modulation + props.strokeWidth) * 2);
const calcHeight = computed(() => 
  props.background
    ? waveHeight.value + props.backgroundHeight
    : waveHeight.value
);

for (let i=0; i < props.width - 20; i++) {
  xs.value.push(i);
}

let elapsed = 0;

function animate() {
  let points = xs.value.map(x => {
    let y = (waveHeight.value / 2) + props.modulation * Math.sin((x + elapsed) / props.spacing);
    return [x + props.strokeWidth, y]; // Offset by 10px to account for padding
  });

  // Build path for upper line
  path.value = "M" + points.map(p => {
    return p[0] + "," + p[1]
  }).join(' L');

  // Build for bottom mask fill
  if (props.background) {
    bottom_path.value = "M" + points.map(p => {
      return p[0] + "," + p[1]
    }).join(' L') + ` L${props.width - props.strokeWidth * 2},${calcHeight.value} L${props.strokeWidth * 2},${calcHeight.value}Z`;
  }

  if (!props.pause) {
    // Update elapsed time and animation frame
    elapsed += props.speed * (props.reverse ? -1 : 1);
    $frameId = requestAnimationFrame(animate);
  }
}

onMounted(() => {
  animate();
})

onBeforeUnmount(() => {
  window.cancelAnimationFrame($frameId);
  $frameId = 0;
})

</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
}

path {
  stroke-linecap: round;
}

path.bottom_path {
  stroke: none;
}
</style>
