<template>
  <footer class="footer">
    <Wave fill="#4B4A67" :height="100" class="footer__wave" :amplitude="25" :points="2"/>
    <div class="footer__inner">
      <div class="grid--four maxw--body block gap--double-ver">
        <div class="footer__column">
          <h5>SUWO</h5>
          <ul>
            <li v-for="menuItem in menuItems" :key="menuItem.id">
              <nuxt-link :to="menuItem.url">{{ menuItem.title }}</nuxt-link>
            </li>
          </ul>
        </div>
        <div class="footer__column">
          <h5>Pagina's</h5>
          <ul>
            <li><nuxt-link to="/blog">Blog</nuxt-link></li>
            <li><nuxt-link to="/faq">Veelgestelde vragen</nuxt-link></li>
            <li><nuxt-link to="/over">Over het SUWO</nuxt-link></li>
            <li><nuxt-link to="/contact">Contact</nuxt-link></li>
          </ul>
        </div>
        <div class="footer__column">
          <h5>Categorieën</h5>
          <ul>
            <li v-for="category in categories" :key="category.id">
              <nuxt-link :to="`/blog/?category[0]=${category.slug}`">{{ category.title }}</nuxt-link>
            </li>
          </ul>
        </div>
        <div class="footer__column">
          <h5>Nieuwste berichten</h5>
          <ul>
            <li v-for="article in articles" :key="article.id">
              <nuxt-link :to="`/blog/${article.slug}`">{{ article.title }}</nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import type BlogArticle from '@/typings/strapi/objects/blogArticle';
import type Category from '@/typings/strapi/objects/category';
import type Menu from '@/typings/strapi/components/menu';
import type MenuItem from '@/typings/strapi/components/menuItem';

const { find, findOne } = useStrapi();

const { data: categories } = await useAsyncData(
  'footer-categories',
  () => find<Category>('categories', { pagination: { pageSize: 5, page: 1 } }),
  {
    transform: flattenCollection,
    lazy: true
  }
)

const { data: articles } = await useAsyncData(
  'footer-articles',
  () => find<BlogArticle>('blog-articles', { pagination: { pageSize: 5, page: 1 }, sort: ['publishedAt:desc'] }),
  {
    transform: flattenCollection,
    lazy: true
  }
)

const { data: menu } = await useAsyncData(
  'footer_menu',
  () => findOne<Menu>('menus', 2, { populate: '*', nested: true }),
  {
    transform: flattenObject
  }
);

const menuItems = computed(() => flattenCollection<MenuItem>(menu.value?.items));
</script>

<style lang="scss">
.footer {
  height: 280px;
  position: relative;
  z-index: 1;
}

.footer__wave {
  margin-bottom: -10px;
}

.footer__inner {
  width: 100%;
  height: auto;
  background: $primary;
  color: $background;
}

.footer__column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;

  h5 {
    color: $background;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  a {
    color: rgba($background, 0.76);
    text-decoration: none;
    font-weight: 500;
    transition: all 215ms ease-in-out;

    &:hover {
      color: $background;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }
}
</style>
