import { useTransitionComposable } from "../composables/useTransitionComposable";

const { toggleTransitionComplete } = useTransitionComposable();

// Appear doesn't work so far, commented on issue:
// https://github.com/nuxt/nuxt/issues/14715#issuecomment-1805011323

export const pageTransitionConfig = {
  // name: 'general-transition',
  mode: 'out-in' as any,
  appear: true,
  // onAppear(el: Element) {
  //   console.log('on appear')
  //   useGsap.set('.loading-screen', { top: -300 });
  // },
  onBeforeEnter(el: Element) {
    useGsap.set('.loading-screen', { top: -300 });
  },
  onEnter: (el: Element, done: () => void) => {
    const tl = useGsap.timeline({
      paused: true,
      onComplete: () => {
        toggleTransitionComplete(true)
        done()
      }
    });

    tl.to(".loading-screen", {
        duration: 0.65,
        top: window.innerHeight,
        ease: "expo.inOut",
        delay: 0.1,
    });

    tl.play();
  },
  onAfterEnter(el: Element) {
    useGsap.set('.loading-screen', { top: '100%' });
  },
  onEnterCancelled(el: Element) {
    useGsap.set('.loading-screen', { top: '100%' });
  },
  onBeforeLeave() {
    useGsap.set('.loading-screen', { top: window.innerHeight });
  },
  onLeave(el: Element, done: () => void) {
    toggleTransitionComplete(false);

    const tl = useGsap.timeline({
      paused: true,
      onComplete: done 
    })

    tl.to(".loading-screen", {
      duration: 0.45,
      top: -300,
      ease: "power2.out",
    });

    tl.play();
  },
  onLeaveCancelled(el: Element) {
    useGsap.set('loading-screen', { top: 'calc(100% + 300px)' });
  }
}

export default pageTransitionConfig;
